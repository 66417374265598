<template>
  <div class="space" id="space">
    <img src="../assets/image/new_return.png" alt="" class="go-back" @click="handleGoBack" v-if="returnIsShow">
    <div class="right">
      <div class="right-top">
        <!-- <select @change="handleChangeSelect($event)" v-model="value" class="select">
          <option v-for="item in areaOptions" :value="item">{{item}}</option>
        </select> -->
        <el-select v-model="value" placeholder="" @change="handleChangeSelect($event)" class="space-select"
          ref="upSelect" v-click-outside="clickOutSide">
          <el-option v-for="item in areaOptions" :label="item.label" :value="item.label" :key="item.label">
          </el-option>
        </el-select>
        <div class="types">
          <div :class="[activeIndex == index?'active-type':'','type']" v-for="(item,index) in rentData" :key="index"
            @click="handleChangeContent(index)">
            <div :class="[item.selected?'selected-type':'','right-type']" @click.stop="handleCheckout(item)"
              v-if="spaceData.phoneStatus">
            </div>
            <div class="left-type">
              <div class="area">{{item.location}}</div>
              <div class="loca">{{item.area}}</div>
            </div>
          </div>
        </div>
        <div class="submit-btn" @click="handleSubmit" v-if="spaceData.phoneStatus">生成推荐方案</div>
      </div>
    </div>
    <div :class="[isIpad == 'false'?'':'ipad-content','content']" v-if="templateType == 0">
      <div id="certify">
        <div class="swiper-container">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="(item,index) in itemData.image" :key="index">
              <img :src="QINIU_CDN_HOST+item.image" @click="handleJumpVr(itemData.id,item,index)" />
              <img src="../assets/image/720.gif" alt="" class="vr-img" @click="handleJumpVr(itemData.id,item,index)"
                v-if="item.data_type == '1'">
              <div class="text">{{item.image_name}}</div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <div class="swiper-box" v-if="templateType == 1">
      <div class="swiper-container" id="top-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in itemData.image" :key="index">
            <img :src="QINIU_CDN_HOST+item.image" @click="handleJumpVr(itemData.id,item,index)" />
            <img src="../assets/image/720.gif" alt="" class="vr-img" @click="handleJumpVr(itemData.id,item,index)"
              v-if="item.data_type == '2' || item.data_type == '1'">
            <div class="text">{{item.image_name}}</div>
          </div>
        </div>
      </div>
      <div :class="[isIpad == 'false'?'':'ipad-thumbs-box','thumbs-box']">
        <div class="swiper-container" id="thumbs">
          <div class="swiper-wrapper">
            <div :class="[activeThumbs== index?'thumbs-slide':'','swiper-slide'] "
              v-for="(item,index) in itemData.image" :key="index" @click="handleClickSlide(index)">
              <img :src="QINIU_CDN_HOST+item.image" />
            </div>
          </div>
        </div>
        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>


    <div class="footer">
      <div class="item-info">
        <div class="text">{{itemData.number}}</div>
        <div class="text">{{itemData.oriented}}</div>
        <div class="text">{{itemData.area + itemData.areas}}</div>
        <div class="text">{{itemData.rent + itemData.rents}}</div>
        <div class="text">{{itemData.dec_status}}</div>
      </div>
      <div class="title">{{spaceData.building_name}}</div>
    </div>
    <div class="pop" v-if="popIsShow">
      <img :src="popImg" alt="">
    </div>
  </div>

</template>
<script>
  // import Clipboard from 'clipboard';
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

  import { ImagePreview } from 'vant'
  import Swipe from 'swiper'
  import 'swiper/dist/css/swiper.min.css'
  import '@vant/touch-emulator'
  // var reg = /^(0|[1-9][0-9]*)$/;

  export default {
    data() {
      var me = this
      return {
        beforePage: false,
        mySwiper: {},
        activeThumbs: 0,
        building_id: '',
        srcList: [],
        spaceData: {},
        rentData: [],
        itemData: {},
        activeIndex: 0,
        rent_id: [],
        title: "",
        phone: "",
        url: "",
        codeUrl: "",
        codeData: {},
        phoneText: '自动填充',
        areaOptions: [],
        value: '全部',
        infoIsShow: true,
        isIpad: false,
        popIsShow: false,
        popImg: '',
        returnIsShow: false,
        templateType:0,//模板
        swiperOptions: {
          watchSlidesProgress: true,
          slidesPerView: 'auto',
          initialSlide: 3,
          observer: true,
          observeParents: true,
          centeredSlides: true,
          loop: true,
          loopedSlides: 3,
          // mousewheel: true,
          on: {
            progress: function (progress) {
              for (var i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i);
                var slideProgress = this.slides[i].progress;
                var modify = 1;
                if (Math.abs(slideProgress) > 1) {
                  modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                }
                var translate = me.isIpad == 'false' ? (slideProgress * modify * 300 + 'px') : (slideProgress * modify * 500 + 'px');
                var scale = me.isIpad == 'false' ? 1 - Math.abs(slideProgress) / 3 : 1 - Math.abs(slideProgress) / 4;
                var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                slide.transform('translateX(' + translate + ') scale(' + scale + ')');
                slide.css('zIndex', zIndex);
                slide.css('opacity', 1);
                if (Math.abs(slideProgress) > 3) {
                  slide.css('opacity', 0);
                }
              }
            },
            click: function (e) {
              if (this.realIndex >= me.itemData.image.length) {
                this.realIndex = 0
              }
              if (!me.itemData.image[this.realIndex].data_type) {
                ImagePreview({
                  images: me.srcList,
                  startPosition: this.realIndex
                })
              } else {
                for (let item of me.itemData.image) {
                  if (item.data_type) {
                    me.handleJumpVr(me.itemData.id, item)
                  }
                }
              }

            }
          }
        },
      };
    },
    components: { Swiper, SwiperSlide, Swipe },
    beforeRouteEnter(to, from, next) {
      if (from.name == null) {
        next(vm => {
          vm.beforePage = true
        })
      } else {
        next()
      }
    },
    methods: {
      clickOutSide() {
        this.$refs.upSelect.blur();
      },
      handleClickImg(i) {
        console.log(i)
      },
      // api/v5/panorama/perfectBook
      // panorama_id，title，phone
      handleChangeSelect(item) {
        this.rent_id = [];
        this.$axios({
          method: "POST",
          url: "api/v5/panorama/channelDetails",
          data: {
            panorama_id: this.$route.query.panorama_id,
            channel_id: this.$route.query.channel_id,
            area: this.value,
            id: this.$route.query.id
          }
        }).then(res => {
          this.spaceData = res.data.data;
          this.areaOptions = [];
          for (var i = 0; i < res.data.data.areaSearch.length; i++) {
            this.areaOptions.push({
              value: i,
              label: res.data.data.areaSearch[i]
            })
          }
          this.rentData = [];
          if (res.data.data.rent.length > 0) {
            let that = this;
            that.itemData = res.data.data.rent[0]
            res.data.data.rent.forEach(item => {
              this.rentData.push({
                area: item.area + item.areas,
                location: item.floor_num + item.floor_nums + ' ' + item.floor + item.floors,
                selected: false,
                id: item.id
              })
            });
            if (this.templateType == 0) {
              this.$refs.mySwiper.$swiper.slideTo(3, 10, false)
            }
            this.infoIsShow = true
          } else {
            this.infoIsShow = false
          }
        })
      },
      //点击下面的thumbs
      handleClickSlide(index) {
        this.activeThumbs = index
      },
      handleGoBack() {
        if (!this.beforePage) {
          this.$axios({
            method: "POST",
            url: "api/v5/panorama/channelDetails",
            data: {
              panorama_id: this.$route.query.panorama_id,
              channel_id: this.$route.query.channel_id,
              area: "全部",
              id: this.$route.query.id
            }
          }).then(res => {
            this.spaceData = res.data.data;
            this.areaOptions = [];
            for (var i = 0; i < res.data.data.areaSearch.length; i++) {
              this.areaOptions.push({
                value: i,
                label: res.data.data.areaSearch[i]
              })
            }
            this.rentData = [];
            this.itemData = {};
            this.$router.go(-1);
            if (res.data.data.rent.length > 0) {
              res.data.data.rent.forEach(item => {
                this.rentData.push({
                  area: item.area + item.areas,
                  location: item.floor_num + item.floor_nums + ' ' + item.floor + item.floors,
                  selected: false,
                  id: item.id
                })
              });
            }
            sessionStorage.setItem("rentData", JSON.stringify(this.rentData))
          })
        } else {
          this.$router.push({
            path: "/"
          })
        }

      },
      getSwiper() {
        let that = this
        const thumbs = new Swipe('#thumbs', {
          // watchSlidesVisibility: true,
          slidesPerView: 'auto',
          slideToClickedSlide: true,
          watchSlidesProgress: false,
          spaceBetween: 20,
          initialSlide: 0,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          // on: {
          //   slideChangeTransitionEnd: function(){
          //     that.activeThumbs = this.activeIndex
          //     console.log(this.activeIndex);//切换结束时，告诉我现在是第几个slide
          //   },
          // }
        })
        this.mySwiper = new Swipe('#top-swiper', {
          // loop: true,
          // allowTouchMove: true,
          initialSlide: 0,
          thumbs: {
            swiper: thumbs,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          on: {
            slideChangeTransitionEnd: function () {
              console.log(this.activeIndex)
              that.activeThumbs = this.activeIndex
            },
          }
        })
      },
      getData() {
        this.$axios({
          method: "POST",
          url: "api/v5/panorama/channelDetails",
          data: {
            panorama_id: this.$route.query.panorama_id,
            channel_id: this.$route.query.channel_id,
            area: this.value,
            id: this.$route.query.id
          }
        }).then(res => {
          this.spaceData = res.data.data;
          this.areaOptions = [];
          this.templateType = res.data.data.template_type
          for (var i = 0; i < res.data.data.areaSearch.length; i++) {
            this.areaOptions.push({
              value: i,
              label: res.data.data.areaSearch[i]
            })
          }
          if (!sessionStorage.getItem('token') || sessionStorage.getItem('from')) {
            res.data.data.rent.forEach(item => {
              this.rentData.push({
                area: item.area + item.areas,
                location: item.floor_num + item.floor_nums + ' ' + item.floor + item.floors,
                selected: false,
                id: item.id
              })
            });
            sessionStorage.setItem("rentData", JSON.stringify(this.rentData))
          } else {
            this.rentData = JSON.parse(sessionStorage.getItem("rentData"))
            this.rentData.forEach(item => {
              if (item.selected) {
                this.rent_id.push(item.id)
              }
            })
          }
          if (res.data.data.rent.length > 0) {
            this.itemData = res.data.data.rent[0]
            if (this.itemData.image.length == 3 && this.templateType == 0) {
              const [first,...rest] = this.itemData.image
              this.itemData.image = [...rest,first]
            }
          
            this.itemData.image.forEach(item => {
              this.srcList.push(this.QINIU_CDN_HOST + item.image)
            })
           
            this.infoIsShow = true
          } else {
            this.infoIsShow = false
          }
          // this.handleChangeContent(0)
          const that = this
          that.$nextTick(() => {
            that.getSwiper()
          })

        })
      },
      handleChangeContent(index) {
        if (this.templateType == 1) {
          this.mySwiper.destroy(false)
          this.activeIndex = index;
          this.activeThumbs = 0
          this.itemData = this.spaceData.rent[index];
          this.srcList = []
          this.itemData.image.forEach(item => {
            this.srcList.push(this.QINIU_CDN_HOST + item.image)
          })
          const that = this
          that.$nextTick(() => {
            that.getSwiper()
          })
        } else {
          this.srcList = []
          this.activeIndex = index;
          this.itemData = this.spaceData.rent[index];
          this.itemData.image.forEach(item => {
            this.srcList.push(this.QINIU_CDN_HOST + item.image)
          })
          if (this.itemData.image.length == 3 && index == 0) {
            this.$refs.mySwiper.$swiper.slideTo(2, 10, false)
          } else {
            this.$refs.mySwiper.$swiper.slideTo(3, 10, false)
          }

        }
      },
      handleJumpVr(id, item, index) {
        if (item.data_type == '1') {
          this.$router.push({
            path: "/outVr",
            query: {
              url: item.data
            }
          })
          // } else if (item.data_type == '2') {
          //   // console.log(this.$route)
          //   this.$router.push({
          //     path: "/vr",
          //     query: {
          //       panorama_id: this.$route.query.panorama_id,
          //       channel_id: 2,
          //       rent_id: id
          //     }
          //   })
        } else {          // this.popIsShow = true
          // this.popImg = this.QINIU_CDN_HOST + item.image
          if (this.templateType == 1) {
            ImagePreview({
              images: this.srcList,
              startPosition: index
            })
          }
        }
      },
      handleCheckout(i) {
        i.selected = !i.selected;
        this.rent_id = []
        this.rentData.forEach(item => {
          if (item.selected) {
            this.rent_id.push(item.id)
          }
        })
        sessionStorage.setItem("rentData", JSON.stringify(this.rentData))
      },

      //生成推荐方案
      handleSubmit() {
        if (sessionStorage.getItem('token')) {
          if (this.rent_id.length > 0) {
            this.$router.push({
              name: "code",
              params: {
                rent: this.rent_id,
                panorama: this.$route.query.panorama_id
              }
            })
          } else {
            alert("请选择一个面积！")
          }
        } else {
          this.$router.push({
            path: "/login"
          })
        }
      },
      getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]); return null;
      }
    },
    created() {
      // alert(sessionStorage.getItem("isPad"))
      // document.getElementById("space").webkitRequestFullscreen()
      this.isIpad = sessionStorage.getItem('isPad');
      this.building_id = this.getQueryString('building_id')
      if (sessionStorage.getItem('token')) {
        this.rentData.forEach(item => {
          if (item.selected) {
            this.rent_id.push(item.id);
          }
        })
      }
      this.getData();
    },
    mounted() {
      console.log(this.beforePage)
      let that = this
      that.returnIsShow = false
      let timer = setInterval(function () {
        // 判断文档和所有子资源(图片、音视频等)已完成加载
        if (document.readyState === 'complete') {
          //执行方法
          that.returnIsShow = true
          window.clearInterval(timer)
        }
      }, 500)
    },
  };
</script>
<style>
  .space .el-input__inner {
    margin-left: 30px;
    margin-bottom: 20px;
    width: 136px;
    height: 50px;
    background: #292b2d;
    font-size: 20px;
    color: #fff;
  }

  .space .el-input__suffix {
    display: none;
  }

  .right-top .el-input__inner {
    -moz-user-select: none;
    /* Firefox */
    -webkit-user-select: none;
    /* WebKit */
    -ms-user-select: none;
    /* IE */
    -khtml-user-select: none;
    /* KHTML */
    -o-user-select: none;
    /* Opera */
    user-select: none;
  }

  /* .swiper-slide .text {
    display: none;
  }

  .swiper-slide-active .text {
    display: inline-block;
  } */
</style>
<style scoped lang='scss'>
  .el-scrollbar__bar {
    opacity: 1 !important;
  }

  .space {
    width: 100vw;
    height: 100vh;
    background: #292b2d;

    .go-back {
      position: absolute;
      width: 44px;
      height: 36px;
      top: 77px;
      left: 72px;
      cursor: pointer;
      z-index: 2;
    }

    .footer {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: space-between;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 140px;
      background: #828485;

      .item-info {
        display: flex;
        align-items: center;

        .text {
          margin: 0 60px;
          font-size: 32px;
          font-family: Microsoft YaHei;
          /* font-weight: bold; */
          color: #FFFFFF;

          &:first-of-type {
            margin-left: 90px;
          }
        }
      }

      .title {
        margin-right: 133px;
        font-size: 40px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
      }
    }

    .content {
      position: absolute;
      top: 0%;
      left: 260px;
      width: calc(100% - 260px);
      height: calc(100vh - 140px);
      /* padding: 0 10%; */
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      /* transform: translateY(-48%); */
      align-items: center;
      background: #292b2d;
    }

    #certify {
      position: relative;
      width: 1200px;
      margin-top: 40px;
    }

    #certify .swiper-container {
      /* padding-bottom: 60px; */
      text-align: center;
    }

    #certify .swiper-slide {
      width: 1000px;
      height: 700px;

      .text {
        margin: 0;
        padding: 0;
        margin-top: 40px;
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
      }

    }

    #certify .swiper-slide img {
      display: block;
      width: 100%;
      height: 600px;
    }

    #certify .swiper-slide .vr-img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 146px;
      height: 118px;
      cursor: pointer;
      transform: translate(-50%, -50%);
    }

    .ipad-content {
      padding: 0 60px;

      #certify {
        width: 100%;
        height: calc(100vh - 345px);
        margin: 0;

        .swiper-container {
          margin-top: 50px;
          width: 100%;
          height: calc(100vh - 385px);

          .swiper-slide {
            position: relative;
            width: 85%;
            height: calc((100vw - 260px)*.48);

            img {
              width: 100%;
              height: 100%;
            }

            .text {
              margin: 0;
              padding: 0;
              margin-top: 40px;
              font-size: 30px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #FFFFFF;
            }

            .vr-img {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 146px;
              height: 118px;
              cursor: pointer;
              transform: translate(-50%, -50%);
            }
          }
        }

      }
    }

    .right {
      position: absolute;
      top: 183px;
      left: 0;
      width: 260px;
      height: calc(100vh - 330px);

      /* .space-select {
        margin: 0 0 45px 30px;
        padding: 0 20px;
        width: 136px;
        height: 50px;
        font-size: 20px;
        color: #fff;
        background: #292b2d;
      } */
      .space-select {
        position: relative;

        &:after {
          position: absolute;
          top: 19px;
          right: 20px;
          content: "";
          width: 14px;
          height: 12px;
          /* transform: translateY(-50%); */
          background: url('../assets/image/space_select.png') no-repeat;
          background-size: 100% 100%;
        }
      }

      .types {
        height: calc(100vh - 600px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
      }

      .type {
        padding-left: 35px;
        margin: 30px 0;
        width: 260px;
        height: 74px;
        background: rgba(216, 216, 216, 0);
        box-sizing: border-box;
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        cursor: pointer;

        .left-type {
          margin-left: 23px;

          .area {
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
          }

          .loca {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
          }
        }


        .right-type {
          cursor: pointer;
          width: 28px;
          height: 20px;
          background: url('../assets/image/new_no_selected.png') no-repeat;
          background-size: 100% 100%;
        }

        .selected-type {
          background: url('../assets/image/new_selected.png') no-repeat;
          background-size: 100% 100%;
        }
      }

      .active-type {
        /* border: 3px solid #000000; */
        background: #828485;
        border-radius: 0 37px 37px 0;
      }

      .submit-btn {
        margin: 40px 0 0 40px;
        width: calc(100% - 40px);
        height: 60px;
        text-align: center;
        line-height: 60px;
        font-size: 26px;
        font-family: Microsoft YaHei;
        color: #FFFFFF;
        letter-spacing: 1px;
        background: #292b2d;
        cursor: pointer;
        border: 1px solid #fff;
      }
    }

    .right-bottom {
      .plan-title {
        margin: 40px 0 6px 0;
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }

      .plan-input {
        width: 100%;
        height: 70px;
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .input-content {
        display: flex;
        align-items: center;

        .phone-url {
          width: 75%;
          height: 70px;
          text-align: center;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }

        .btn {
          width: 25%;
          height: 70px;
          line-height: 70px;
          text-align: center;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          background: #333;
          cursor: pointer;
        }
      }

      .code-img {
        margin-top: 64px;
        width: 176px;
        height: 175px;

      }
    }
  }

  .pop {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: rgba(.3, .3, .3, .3);
    z-index: 1000;

    img {
      display: inline-block;
      width: auto;
      height: 100vh;
    }
  }

  .swiper-box {
    margin-left: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 140px);

  }

  #top-swiper {
    position: relative;
    width: 1090px;
    height: 646px;

    .text {
      position: absolute;
      left: 20px;
      bottom: 20px;
      padding: 8px 12px;
      color: #fff;
      font-size: 14px;
      background: rgba(41, 43, 45, .77);
    }

    .swiper-slide {
      img {
        width: 100%;
        height: 100%;
      }

      .vr-img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 146px;
        height: 118px;
        cursor: pointer;
        transform: translate(-50%, -50%);
      }
    }

  }

  .thumbs-box {
    position: relative;
    margin-top: 20px;
  }

  .ipad-thumbs-box {
    margin-top: 40px;
  }

  #thumbs {
    width: 990px;
    height: 124px;

    .swiper-wrapper {
      width: 990px;
    }

    .swiper-slide {
      position: relative;
      width: 124px;
      height: 124px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(.3, .3, .3, .3);
        z-index: 10;
      }

      img {
        width: 124px;
        height: 100%;
      }
    }

    .thumbs-slide {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 10;
      }
    }

    /* .swiper-slide-active {
      position: relative;
      ;
      width: 175px;
      height: 175px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 10;
      }

      img {
        width: 175px;
        height: 100%;

      }

    } */
  }

  .swiper-button-next {
    margin-top: -62px;
    width: 40px;
    height: 124px;
    right: -50px;
    z-index: 100;
    background-image: url('../assets/image/page_next.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .swiper-button-prev {
    margin-top: -62px;
    width: 40px;
    height: 124px;
    left: -50px;
    z-index: 100;
    background-color: rgba(999, 999, 999, .1);
    background-image: url('../assets/image/page_prev.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
</style>